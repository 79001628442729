<template>
    <div>
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              type="search"
              name="plantCd"
              v-model="searchParam.plantCd"
            ></c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              codeGroupCd="ISSUES_ITEM_CLASS_CD"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="issuesClassCd"
              label="품질/환경/안전보건"
              v-model="searchParam.issuesClassCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-select
              codeGroupCd="ISSUES_ITEM_TYPE_CD"
              type="search"
              itemText="codeName"
              itemValue="code"
              name="issuesTypeCd"
              label="평가대상구분"
              v-model="searchParam.issuesTypeCd"
            ></c-select>
          </div>
        </template>
      </c-search-box>
      <c-table
        ref="table"
        title="기준정보 목록"
        :columns="grid.columns"
        :selection="popupParam.type"
        rowKey="issuesItemId"
        :data="grid.data"
        :editable="editable"
        @rowDblclick="rowDblclick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <!--검색-->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
              <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  <script>
  import selectConfig from '@/js/selectConfig';
  export default {
    name: "internal-external-issues-item-pop",
    props: {
      popupParam: {
        type: Object,
        default: function() {
          return {
            type: 'single',
            plantCd: '',
            issuesClassCd: null,
          }
        },
      },
      contentHeight: {
        type: [String, Number],
        default: function() {
          return 'auto'
        },
      }
    },
    data() {
      return {
        grid: {
          columns: [
            {
              name: 'plantName',
              field: 'plantName',
              label: 'LBLPLANT',
              style: 'width:100px',
              align: 'center',
              sortable: false,
            },
            {
              name: "issuesClassName",
              field: "issuesClassName",
              label: "품질/환경/안전보건",
              style: 'width:100px',
              align: "center",
              sortable: false,
            },
            {
              name: "issuesTypeName",
              field: "issuesTypeName",
              label: "평가대상구분",
              style: 'width:100px',
              align: "center",
              sortable: false,
            },
            {
              name: "issuesName",
              field: "issuesName",
              label: "평가대상명",
              style: 'width:200px',
              align: "left",
              sortable: false,
            },
          ],
          data: [],
        },
        searchParam: {
          plantCd: null,
          issuesClassCd: null,
          issuesTypeCd: null,
          useFlag: 'Y',
        },
        editable: true,
        listUrl: '',
        popupOptions: {
          target: null,
          title: "",
          visible: false,
          top: "",
          param: {},
          closeCallback: null,
        },
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {},
    methods: {
      init() {
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.sai.iei.item.list.url;

        if (this.popupParam.plantCd) {
          this.searchParam.plantCd = this.popupParam.plantCd
        }
        if (this.popupParam.issuesClassCd) {
          this.searchParam.issuesClassCd = this.popupParam.issuesClassCd
        }
        // url setting
        // code setting
        // list setting
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      },
      select() {
        let selectData = this.$refs['table'].selected;
        if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
            type: 'warning', // success / info / warning / error
          });
        } else {
          this.$emit('closePopup', selectData);
        }
      },
      rowDblclick(row) {
        if (this.popupParam.type === 'single') {
          this.$emit('closePopup', [row]);
        }
      },
    },
  };
  </script>
  